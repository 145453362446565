import expertList from "../src/view/expert/list.vue"
import expertDetail from "../src/view/expert/detail.vue"
import login from "../src/view/login.vue"
export default [{
		path: '/login',
		component: login
	}, {
		path: '/',
		component: expertList
	},
	{
		path: '/detail',
		component: expertDetail
	},
]
