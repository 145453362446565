<template>
	<div id="expert_list">

		<article class="main">
			<div class="search">
				<el-input v-model="search_data.keyword" placeholder="请输入您要搜索的内容"></el-input>
				<el-button type="primary" @click="do_search()">搜索</el-button>
			</div>
			<div class="search-listbox search-margin">

				<div class="sslist-ylxs">
					<strong>所在地：</strong>
					<div :class="city_cate.active ? 'active' : 'unfold'" class="sslist-ssnr">
						<div class="li" :class="{'active':city_cate.li_index==0}" @click="tagChange(0,0,'不限')">不限</div>
						<div class="li" v-for="(s, k) in cate" :class="{'active':city_cate.li_index==k+1}" :key="k"
							@click="tagChange(0,k+1,s.label)">
							{{s.label}}
						</div>
					</div>
					<span :class="city_cate.active ? 'item-down' : 'item-span'"
						v-html="city_cate.activeactive ? '收起' : '展开'"
						@click="city_cate.active = !city_cate.active"></span>
				</div>
				<div class="sslist-ylxs">
					<strong>大学：</strong>
					<div :class="school.active ? 'active' : 'unfold'" class="sslist-ssnr">
						<div class="li" :class="{'active':school.li_index==0}" @click="tagChange(1,0,'不限')">
							不限
						</div>
						<template v-for="(s, k) in school.list">

							<div class="li" :class="{'active':school.li_index==k+1}" v-if="s"
								@click="tagChange(1,k+1,s)" :key="k">
								{{s}}
							</div>
						</template>
					</div>
					<span :class="school.active ? 'item-down' : 'item-span'" v-html="school.active ? '收起' : '展开'"
						@click="school.active = !school.active"></span>
				</div>
			</div>
			<div class="contentMain">
				<div class="infinite-list-wrapper" style="overflow:initial">
					<div class="list">
						<div class="expertitem" v-for="(v, k) in list" :key="k">
							<!-- :style="'background: url('+v.photo+') no-repeat; background-size: cover; background-position: center top; '" -->
							<div class="touxiang" @click="expert_detail(v.id)">
								<el-image style="width: 130x; height: 131px" :src="v.photo" fit="cover">
									<div slot="error" class="image-slot">
										{{ v.name.slice(0,1) }}
									</div>
								</el-image>
							</div>
							<!-- <div v-else class="touxiang">
								<div slot="error" class="image-slot">
									{{ v.name.slice(0,1) }}
								</div>
							</div> -->
							<span class="name" @click="expert_detail(v.id)">{{ v.name }}</span>
							<p class="kind">{{ v.school }} &nbsp; {{ v.title }}</p>
							<p v-if="v.major">
								学科分类：
								<span>{{ v.major }}</span>
							</p>
							<div class="lingyu">
								产业领域：
								<span>{{ v.research_field }}</span>

							</div>
							<button class="contact" @click="expert_detail(v.id)">查看详情</button>
						</div>
					</div>
				</div>
				<div class="pagination">
					<el-pagination background @current-change="handleCurrentChange" :current-page.sync="currentPage"
						:page-size="search_data.limit" layout="total, prev, pager, next, jumper" :total="count">
					</el-pagination>
				</div>

			</div>
		</article>

	</div>
</template>

<script>
	import city_school from "@/assets/js/school.json"
	export default {
		components: {

		},
		data() {
			return {
				currentPage: 1,
				search_data: {
					limit: 16,
					page: 1,
					status: 1,
					school_arr: [],
					keyword: '',
				},
				list: [],
				count: 0,
				reload: false,
				loading: true,
				city_cate: {
					active: false,
					li_index: 0,
				},
				cate: [],
				all_school: [],
				school: {
					active: false,
					list: [],
					li_index: 0,
				}
			};
		},
		watch: {
			search_data: { //监听的对象
				deep: true, //深度监听设置为 true
				handler: function(newV) {
					console.log(newV)
				}
			},
			$route() {
				let query = this.$route.query;
				if (typeof query.page != 'undefined') {
					this.search_data.page = parseInt(query.page);
					this.currentPage = parseInt(query.page);
				} else {
					this.search_data.page = 1;
					this.currentPage = 1;
				}
				if (typeof query.keyword != 'undefined') {
					this.search_data.keyword = query.keyword;
					this.currentPage = 1;
					this.search_data.page = 1;
				}

				this.search();
			}
		},
		computed: {
			noMore() {
				return this.count >= 40;
			},
			disabled() {
				return this.loading;
			}
		},
		created() {
			var querys = this.$route.query;
			if (typeof querys.keyword != 'undefined') {
				this.search_data.keyword = querys.keyword;
			}
			if (typeof querys.page != 'undefined') {
				this.search_data.page = parseInt(querys.page);
				this.currentPage = parseInt(querys.page);
			} else {
				this.search_data.page = 1;
				this.currentPage = 1;
			}

		},
		mounted() {
			this.get_json_cate();

			if (sessionStorage.getItem('city_index')) {
				this.city_cate.li_index = sessionStorage.getItem('city_index')
			}
			if (sessionStorage.getItem('school_index')) {
				this.school.li_index = sessionStorage.getItem('school_index')
			}
			if (sessionStorage.getItem('search_data')) {
				this.search_data = JSON.parse(sessionStorage.getItem('search_data'))
			}
			this.$nextTick(() => {
				this.search();
			});
		},
		methods: {
			get_json_cate() {
				this.cate = city_school;
				let that = this
				this.cate.forEach(function(v) {
					that.school.list = that.school.list.concat(v.child)
				})
				this.all_school = that.school.list;
			},
			get_cate(type) {
				this.$http_qm.post('mentor/cate', {
					type: type,
				}).then(res => {
					if (res.code == 200) {
						if (type == 'addr_school') {
							this.cate = res.data;
							let that = this
							this.cate.forEach(function(v) {
								that.school.list = that.school.list.concat(v.child)
							})
							this.all_school = that.school.list;
						}
					}
				}).catch(err => {
					console.log(err)
				})
			},
			tagChange(type, k, v) {
				console.log(type, k, v);
				let that = this;
				if (type == 0) {
					this.city_cate.li_index = k;
					sessionStorage.setItem('city_index', k)
					if (k == 0) {
						that.school.list = that.all_school;
						that.search_data.school_arr = [];
					} else {
						that.school.list = that.cate[k - 1].child;
						that.search_data.school_arr = that.school.list;

					}
					this.school.li_index = 0
				} else if (type == 1) {
					this.school.li_index = k
					sessionStorage.setItem('school_index', k)
					that.search_data.school_arr = v == '不限' ? [] : [v];
				}
				this.search_data.page = 1;
				sessionStorage.setItem('search_data', JSON.stringify(this.search_data))
				this.search();
			},
			handleCurrentChange(page) {
				this.search_data.page = this.currentPage = page;
				sessionStorage.setItem('search_data', JSON.stringify(this.search_data))
				this.currentPage = page;
				this.$router.push({
					path: '/',
					query: {
						page: page
					}
				});

				document.querySelector('#app').scrollIntoView({
					behavior: 'auto'
				});
			},
			do_search() {
				this.search_init();

				sessionStorage.setItem('search_data', JSON.stringify(this.search_data))
				this.$router.push({
					path: '/',
					query: {
						page: 1,
						keyword: this.search_data.keyword
					}
				});
			},
			search_init() {
				this.reload = true;
				this.search_data.page = 1;
				this.loading = true;
			},
			async search() {
				let search_data = this.search_data;
				await this.$http_qm
					.post('mentor/list', search_data)
					.then(res => {
						this.list = res.data;
						this.count = res.count;
					})
					.catch(err => {
						console.log(err);
					});
			},
			createFilter(queryString) {
				return restaurant => {
					return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
				};
			},

			load() {
				let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				let clientHeight = document.documentElement.clientHeight;
				let scrollHeight = document.documentElement.scrollHeight;
				if (scrollTop + clientHeight >= scrollHeight) {
					// 滚动到底部，逻辑代码
					if (this.loading) {
						this.search();
					}
				}
			},
			expert_detail(id) {
				this.$router.push({
					path: '/detail',
					query: {
						id: id
					}
				});
			}
		},


	};
</script>

<style type="text/css" lang="less" src="../../assets/css/expert.less"></style>
