import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import routerList from './list'


const router = new Router({
	mode: 'history',
	routes: routerList
})
router.beforeEach((to, from, next) => {
	let token = sessionStorage.getItem('token');
	let user = JSON.parse(sessionStorage.getItem('user'));
	console.log(user);
	if (!token || user == '' || user.user_type != 5) {
		if (to.path != '/login') {
			return next('/login')
		}
	}

	next()

})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
export default router
