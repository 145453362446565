<template>
	<div id="expert_detail">
		<div class="menu-nav">
			<div class="container">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<span @click="back()" class="el-breadcrumb__item"><span role="link"
							class="el-breadcrumb__inner">专家列表</span><i
							class="el-breadcrumb__separator el-icon-arrow-right"></i></span>
					<!-- <el-breadcrumb-item>专家列表</el-breadcrumb-item> -->
					<el-breadcrumb-item>专家详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="banner-expert-detail"></div>
		<div class="container">
			<div class="detail-content clearfix">
				<div class="detail-content-fl fl">
					<el-image class="touxiang" style="width: 130px; height: 130px" :src="data.photo" fit="cover">
						<div slot="error" class="image-slot" v-if="data.name">
							{{data.name.slice(0,1)}}
						</div>
					</el-image>
					<p><span class="expertname">{{data.name}}</span> {{data.title}}</p>
				</div>
				<div class="detail-content-fr fr">

					<div class="intro  clearfix">

						<div class="icon icon-type">性别：
							<template v-if="data.sex">{{data.sex}}
							</template>
						</div>
						<!-- <div class="icon icon-type">招生专业：
							<template v-if="data.major">{{data.major}}
							</template>
						</div> -->
						<div class="icon icon-unit">所属院校：{{data.school}}</div>
						<div class="icon icon-unit">所属院系：{{data.department}}</div>
						<div class="icon icon-category">研究领域：
							{{data.research_field}}
						</div>

					</div>

				</div>
			</div>
			<div class="intro-detail">
				<div class="intro-detail-item" v-if="data.profile">
					<div class="tit">联系方式</div>
					<p>
						电话 ：{{data.phone}}
					</p>
					<p>
						邮箱 ：{{data.email}}
					</p>
					<p>
						地址 ：{{data.addr}}
					</p>
				</div>
				<div class="intro-detail-item" v-if="data.profile">
					<div class="tit">个人简述</div>
					<p v-html="data.profile"></p>
				</div>
				<div class="intro-detail-item" v-if="data.scientific_research">
					<div class="tit">科研工作</div>
					<p v-html="data.scientific_research"></p>
				</div>

				<div class="intro-detail-item" v-if="data.research_projects">
					<div class="tit">在研项目</div>
					<p v-html="data.research_projects"></p>
				</div>
				<div class="intro-detail-item" v-if="data.academic_papers">
					<div class="tit">学术论文</div>
					<p v-html="data.academic_papers"></p>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				id: '',
				data: {},
				expert_list: [],
				collect: false,
				mp_code: '',
			}
		},
		mounted() {
			var querys = this.$route.query
			this.id = querys.id
			this.detail();
		},
		methods: {
			back() {
				// this.$router.go(-1);
				window.history.back();  
			},
			detail() {
				this.$http_qm.post('mentor/detail', {
					id: this.id
				}).then(res => {
					this.data = res.data;
				}).catch(err => {
					console.log(err)
				})
			},

		}
	}
</script>
<style lang="less" src="../../assets/css/expert.less"></style>
