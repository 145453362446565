import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: '',
		user: {},
	},
	mutations: {

		setLogin(state, provider) {
			console.log(provider);
			state.user = provider;
			state.token = provider.access_token;
			sessionStorage.setItem("user", JSON.stringify(provider))
			sessionStorage.setItem("token", provider.access_token)
		},

		setLogout(state) {
			state.user = {};
			state.token = '';
			sessionStorage.removeItem('user');
			sessionStorage.removeItem('token');
		},
	},
	actions: {

	},
	getters: {
		user: state => {
			let user = state.user

			if (typeof(user.id) == 'undefined') {
				if (sessionStorage.getItem('user') != '') {
					user = JSON.parse(sessionStorage.getItem('user'))
				} else {
					user = {}
				}
			}
			return user
		},
		token: state => {
			let token = state.token
			if (!token) {
				token = (sessionStorage.getItem('token') || null)
			}
			return token
		},
	},
})
