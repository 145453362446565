<template>
	<div id="login">
		<div class="row1 flex">
			<div class="col1">
				<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/tutor/img2.jpg">
			</div>
			<div class="col2">

				<div class="form flex flex-column">
					<img class="logo" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/app/logo142x117.png" alt="">
					<h1>科技牛专家库</h1>
					<input v-model="form.tel" placeholder="请输入账号" />
					<input type="password" v-model="form.password" placeholder="请输入密码" />
					<el-button @click="onSubmit">登录</el-button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		mapMutations
	} from "vuex"
	export default {
		data() {
			return {
				form: {
					tel: '',
					password: ''
				}
			}
		},
		methods: {
			...mapMutations(['setLogin']),
			onSubmit() {
				this.$http.post('user/mentor_login', this.form).then(res => {
					if (res.code == 200) {
						this.setLogin(res.data);
						this.$router.push({
							path: '/'
						})
					} else {
						this.$message(res.msg);
					}
				}).catch(err => {
					console.log(err);
				})

			},
		}
	}
</script>

<style lang="less">
	#login {
		width: 100%;
		height: 1080px;
		background: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/tutor/img1.jpg) no-repeat center center;
		display: flex;

		.logo {
			width: 142px;
			height: 117px;
			margin-top: 45px;
		}

		.row1 {
			width: 1400px;
			margin: auto;

			.col2 {
				width: 597px;
				background: #3964DE;
				color: #FFFFFF;

				h1 {
					text-align: left;
				}

				.form {
					width: 400px;
					margin: auto;

					input {
						background: #3964DE;
						border: none;
						font-size: 16px;
						border-bottom: 2px solid #fff;
						outline: none;
						padding: 13px 0px;
						margin-top: 45px;
						color: #FFFFFF;
						font-weight: 500;

						&::placeholder {
							color: #FFFFFF;
							font-size: 16px;
							font-weight: 500;
						}

					}

					button {
						margin-top: 94px;
					}
				}
			}
		}
	}

	.flex-column {
		flex-direction: column;
	}

	.flex {
		display: flex;
	}
</style>
