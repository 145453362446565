import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
import router from '../router/index2.js'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import {
	http,
	httpqm,
	baseUrl,
	baseUrlqm
} from './api/index'

//禁止右键
// Vue.directive('preventright', {
// 	bind: function(el) {
// 		// if (process.env.NODE_ENV !== 'development') {
// 		el.oncontextmenu = function() {
// 			return false
// 		}
// 		// }
// 	}
// })

Vue.prototype.$http_qm = httpqm
Vue.prototype.$http = http
Vue.prototype.baseUrl = baseUrl
Vue.prototype.baseUrlqm = baseUrlqm

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
